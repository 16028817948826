html {
  min-height: 100%;
  position: relative;
  background-color: #ECECEC;
}

body {
  height: 100%;
  background-color: #ECECEC;
  font-family: 'Nunito Sans', 'Open Sans', sans-serif;
  color: #191613;
}

.error-container {
  display: none;
}

.error-alert {
  margin: 10px 0 0;
}

.error-icon {
  height: 20px;
  margin-right: 5px;
}

.footer {
  height: 91px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  bottom: 0;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 0;
  text-align: center;
}

.footer.absolute {
  position: absolute;
}

.footer.relative {
  position: relative;
}

.container__customize {
  padding: 10px;
  overflow-x: hidden;
}

.customized-row {
  margin-left: 0;
  margin-right: 0;
}

.f5f5f5-bg {
  background-color: #F5F5F5;
}

.mr-minus-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.mr-10 {
  margin: 10px;
}

.mrt-0 {
  margin-top: 0;
}

.mrb-0 {
  margin-bottom: 0;
}

.mrl-5 {
  margin-left: 5px;
}

.receipt-item-quantity {
  width: calc(100% - 90px);
  position: absolute;
  text-align: right;
}

.pd10 {
  padding: 10px;
}

.pointer {
  cursor: pointer;
}

.disabled-button {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.ajax-spinner {
  display: inline;
}

.ajax-spinner--28p {
  width: 28px;
  height: 28px;
}

.status__ajax-spinner {
  display: inline;
  margin-top: -8px;
}

.ajax-spinner--20p {
  width: 20px;
  height: 20px;
}

.blink-text {
  animation: blinker 750ms linear infinite;
}

.blink-text-once {
  animation: blinker 1000ms linear normal;
}

.full-screen-switcher {
  cursor: pointer;
}

.disabled-link {
  pointer-events: none;
}

/* pat modes */
.table-details-body__transactions .floor-plan-mode__container,
.table-details-body__products .floor-plan-mode__container {
  display: none;
}

.table-details-body__transactions .table-details-mode__container,
.table-details-body__products .table-details-mode__container {
  display: block;
}

.table-details-body__transactions .table-details-mode__container .products,
.table-details-body__products .table-details-mode__container .closed-tables-transactions-container {
  display: none;
}

.table-details-body__products .table-details-mode__container .products,
.table-details-body__transactions .table-details-mode__container .closed-tables-transactions-container {
  display: block;
}


/* floor-plan */
.floor-plan-container {
  margin-bottom: 10px;
  background-color: #FFF;
}

.floor-plan-image-container {
  padding: 10px;
  background-size: contain;
  background-position: center;
  margin-top: 15px;
  display: grid;
  display: -moz-grid;
}

.floor-plan-image-container img {
  width: 100%;
  height: 100%;
}

.floor-plan-tables-container {
  margin-top: -80%;
}

.floor-plan-image-container__floor-plan {
  width: 100%;
}

.floor-plan-circles-container__circle-container {
  padding: 5px 0 10px;
}

.floor-plan-circles-container__circle {
  height: 28px;
  width: 28px;
  border: 1px solid #191613;
  border-radius: 15px;
  margin: 10px auto;
}

.floor-plan-circles-container__circle.red {
  background-color: #F33953;
}

.floor-plan-circles-container__circle.green {
  background-color: #fff;
}

.floor-plan-circles-container__circle.white {
  background-color: #FFA300;
}

@keyframes blinker {
  50% {
    opacity: 0.1;
  }
}

/* floor-plan-tables */
.restaurant-tables {
  background-size: 100% 100% !important;
  backface-visibility: hidden !important;
  -webkit-backface-visibility: hidden !important;
  text-align: center;
  color: #191613;
  position: absolute;
  display: grid;
  display: -moz-inline-grid;
  cursor: pointer;
}

.restaurant-tables.free-table:hover,
.restaurant-tables.occupied-table:hover {
  cursor: pointer;
}

.occupied-table {
  background-color: #F33953;
}

.table-number {
  font-size: 1.1em;
  font-weight: bold;
  margin: auto;
}

.occupied-table .table-number {
  color: #FFF;
}

.free-table {
  background-color: #fff;
}

.free-table .table-number {
  color: #191613;
}

.locked-table {
  background-color: #FFA300;
}

.locked-table .table-number {
  color: #191613;
}

.table-1 {
  margin: 24% 0 0 7%;
  transform: rotate(28deg);
}

.table-1 .table-number {
  transform: rotate(-28deg);
}

.table-2 {
  margin: 10% 0 0 19%;
  transform: rotate(61deg);
}

.table-2 .table-number {
  transform: rotate(-61deg);
}

.table-3 {
  margin: 7% 0 0 39%;
  transform: rotate(89deg);
}

.table-3 .table-number {
  transform: rotate(-90deg);
}

.table-4 {
  margin: 7% 0 0 59%;
  transform: rotate(89deg);
}

.table-4 .table-number {
  transform: rotate(-90deg);
}

.table-5 {
  margin: 24% 0 0 34%;
}

.table-6 {
  margin: 52% 0 0 18%;
}

.table-7 {
  margin: 52% 0 0 40%;
}

.table-8 {
  margin: 28% 0 0 68%;
}

/* modal */
.modal-background {
  visibility: hidden;
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  z-index: 1000;
}

.modal-background.show {
  position: absolute;
  visibility: visible;
  background: rgba(0, 0, 0, 0.5);
}

.modal {
  overflow: visible;
  display: block;
  visibility: hidden;
  opacity: 0;
  transition: opacity 250ms ease-in, visibility 0ms ease-in 250ms;
}

.modal.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 250ms ease-in, visibility 0ms ease-in 0ms;  
}

.custom-modal {
  top: 25%;
}

.custom-modal-large {
  width: unset;
}

.custom-modal .modal-content,
.custom-modal-large .modal-content {
  border-radius: 0;
}

.custom-modal .modal-header,
.custom-modal-large .modal-header {
  background-color: #191613;
  color: #FFF;
  font-size: 24px;
}

.custom-modal .modal-body,
.custom-modal-large .modal-body {
  padding-top: 0;
  padding-bottom: 0;
  color: #191613;
}

.custom-modal .modal-title,
.custom-modal-large .modal-title {
  font-size: 24px;
}

.custom-modal .modal-pdq-link__container,
.custom-modal .modal-pdq-reports-link__container {
  border-bottom: 1px solid #DDD;
}

.custom-modal .modal-pdq-link__container:last-child,
.custom-modal .modal-pdq-reports-link__container:last-child {
  border-bottom: 0;
}

.custom-modal .modal-pdq-link__container:hover,
.custom-modal .modal-pdq-reports-link__container:hover {
  background-color: #eee;
}

.custom-modal .modal-pdq-link,
.custom-modal .modal-pdq-reports-link {
  padding: 10px;
  display: block;
  font-size: 20px;
  text-decoration: none;
  color: #191613;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.modal-reports {
  top: 0;
}

.modal-reports > div {
  top: 25%;
}

.modal-reports .modal-content {
  background-color: #ECECEC;
}

.modal-reports .modal-body {
  padding: 15px;
}

/* business-brand */
.business-brand {
  display: block;
  background-color: #FFF;
  margin-bottom: 10px;
}

.business-brand__client-logo {
  display: inline;
}

.business-brand__client-logo img {
  height: 39px;
  width: 69px;
  margin: -13px 10px 0 0;
}

.cafe-body .business-brand__client-logo img {
  height: 44px;
  width: 50px;
}

.business-brand__client-name {
  display: inline;
  font-size: 28px;
  color: #191613;
  line-height: 68px;
  font-weight: bold;
}

/* receipt */
.status-row,
.receipt-and-products-container,
.floor-plan-mode__container,
.section-name-row {
  margin-left: 0;
  margin-right: 0;
}

.status-text,
.section-name-text {
  font-size: 22px;
  color: #191613;
  line-height: 25px;
  padding: 10px 0 17px;
}

.section-name-row {
  background: #191613;
  height: 42px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.section-name-text {
  font-size: 24px;
  line-height: 25px;
  color: #FAF9F9;
  margin-left: 10px;
}

.mode-close-button {
  background-color: #FAF9F9;
  margin: 7px;
  height: 28px;
  width: 28px;
  text-align: center;
  line-height: 28px;
  font-size: 18px;
  border-radius: 16px;
}

.receipt {
  height: 265px;
  background-color: #FFF;
  padding: 0;
  overflow-y: auto;
  position: relative;
}

.receipt-before-zigzag {
  background: linear-gradient(-135deg, #F5F5F5 5px, transparent 0), linear-gradient(135deg, #F5F5F5 5px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 16px 16px;
  content: " ";
  display: block;
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 16px;
}

.receipt-before-zigzag,
.receipt-after-zigzag {
  background-color: #FFF;
  /*margin-top: -15px;*/
}

.receipt-after-zigzag:after {
  background: linear-gradient(-45deg, #F5F5F5 6px, transparent 0), linear-gradient(45deg, #F5F5F5 5px, transparent 0);
  background-position: left-bottom;
  background-repeat: repeat-x;
  background-size: 16px 16px;
  content: " ";
  display: block;
  position: sticky;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 16px;
}

.receipt__selected-product__container {
  padding: 3px 15px;
  width: 100%;
  display: inline-block;
  font-size: 11px;
}

.receipt__selected-product__container.selected {
  background-color: #9dd9f9;
}

.receipt__modified-product {
  font-size: 9px;
}

.receipt__modified-prouct-name {
  padding-left: 15px;
}

.removableProduct, .removableModifiableProduct {
  cursor: pointer;
}

.removableProduct:hover {
  background-color: rgb(185, 222, 241);
}

.removableProduct.selectedRemovableProduct {
  background-color: rgb(157, 217, 249);
}

.removableModifiableProduct:hover {
  background-color: rgb(207, 231, 243);
}

.removableModifiableProduct.selectedModifiableRemovableProduct {
  background-color: rgb(182, 225, 248);
}

.payments {
  margin: 15px 0;
  font-size: 20px;
  line-height: 25px;
}

.cafe-body .payments {
  margin: 10px 0;
}

.cafe-body .payments-row-container {
  line-height: 30px;
}

.payments__amount {
  font-weight: bold;
  float: right;
}

.custom-products-row {
  padding: 0;
}

.products__product {
  float: left;
  padding: 5px;
}

.products__product-center {
  margin-left: 10px;
  margin-right: 5px;
}

.product-click-container {
  cursor: pointer;
  user-select: none;
}

.products__product__image-container {
  border: 1px solid #ccc;
  border-radius: 3px;
  height: 58px;
  background-color: #FFF;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 36px;
}

.coffee {
  background-image: url('../images/coffee.png');
}

.chicken {
  background-image: url('../images/chicken.png');
}

.beer {
  background-image: url('../images/beer.png');
}

.tea {
  background-image: url('../images/tea.png');
}

.fish {
  background-image: url('../images/seafood.png');
  background-size: 40px;
}

.wine {
  background-image: url('../images/wine.png');
}

.soda {
  background-image: url('../images/soda.png');
}

.burger {
  background-image: url('../images/burger.png');
}

.spirit {
  background-image: url('../images/spirit.png');
}

.water {
  background-image: url('../images/water.png');
}

.pizza {
  background-image: url('../images/pizza.png');
}

.dessert {
  background-image: url('../images/dessert.png');
}

.chevron-right-double {
  background-image: url('../images/chevron-right-double.png');
}

.chevron-left-double {
  background-image: url('../images/chevron-left-double.png');
}

.chevron-right-single {
  background-image: url('../images/chevron-right-single.png');
}

.chevron-left-single {
  background-image: url('../images/chevron-left-single.png');
}

.capture-payment-button {
  height: 50px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #FFF;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px;
  margin-bottom: 10px;
}

.cashew-milk, .coconut-milk, .extra-cheese, .extra-large, .extra-patty, .large, .medium, .oat-milk, 
.small, .vegan-cheese, .no-cheese, .no-tomatos, .no-tomatos, .no-bun, .french-fries, .bogof-promo,
.glass, .shot, .wine-glass {
  background-size: contain;
  padding: 5px;
  background-origin: content-box;
}

.products__product__text {
  background: #191613;
  color: #FFF;
  line-height: 27px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.format-bold {
  font-weight: bold;
}

.format-double-width {
  transform: scaleX(1.3);
  -webkit-transform: scaleX(1.3);
  -moz-transform: scaleX(1.3);
}

.format-double-height {
  transform: scaleY(1.3);
  -webkit-transform: scaleY(1.3);
  -moz-transform: scaleY(1.3);
}

.format-double-width-height {
  transform: scaleX(1.3) scaleY(1.3);
  -webkit-transform: scaleX(1.3) scaleY(1.3);
  -moz-transform: scaleX(1.3) scaleY(1.3);
}

.report {
  padding: 10px;
}

/* buttons */
.buttons {
  margin: 20px 0;
}

.cafe-body .buttons {
  margin: 10px 0;
}

.buttons__button__container {
  padding-right: 5px;
  padding-left: 5px;
}

.buttons__button {
  width: 1.3px;
  height: 50px;
  text-transform: uppercase;
  color: #FFF;
  margin-bottom: 10px;
  border: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  font-size: 15px;
  padding: 0 25px;
  user-select: none;
}

.cafe-body .buttons__button {
  margin-bottom: 0;
}

.button__cancel-transaction,
.button__remove {
  background: #FF4655;
}

.button__clear {
  background: #7084FF;
}

.button__reports {
  background: black;
}

.button__card-payment {
  background: #00A699;
}

/* Transactions */
.transactions {
  margin-bottom: 20px;
  max-height: 400px;
  overflow: auto;
}

.transactions__transactions-header {
  background-color: #191613;
  color: #FFF;
  padding: 10px 0;
  font-size: 18px;
}

.cafe-body .transactions__transactions-header {
  font-size: 24px;
  padding: 15px 0;
}

.transactions__transactions-table {
  margin-bottom: 0;
  border-left: 1px solid #191613;
  border-right: 1px solid #191613;
  border-bottom: 1px solid #191613;
  table-layout: fixed
}

.cafe-body #closedTables.transactions__transactions-table.table tr:nth-child(n+14) {
  display: none;
}

.transactions__transactions-table.table tr th:first-child,
.transactions__transactions-table.table tr td:first-child {
  width: 110px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  padding-left: 10px;
}

.transactions__transactions-table.table tr th:last-child,
.transactions__transactions-table.table tr td:last-child {
  width: 150px;
}

.cafe-body .transactions__transactions-table.table tr th:first-child,
.cafe-body .transactions__transactions-table.table tr td:first-child,
.cafe-body .transactions__transactions-table.table tr th:last-child,
.cafe-body .transactions__transactions-table.table tr td:last-child {
  width: auto;
  word-break: normal;
}

.transactions__transactions-table.table th {
  background-color: #FFF;
  border-bottom: 0;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 8px;
}

.transactions__transactions-table.table td {
  background-color: #FFF;
  word-break: break-all;
  border-top: 0;
  padding: 4px;
  font-size: 12px;
  font-weight: 600;
}

.cafe-body .transactions__transactions-table.table td {
  padding: 9px;
  font-size: 14px;
}

.transactions__transactions-table.table tr.row-with-transaction:hover td,
.transactions__transactions-table.table tr.row-with-transaction.selected td {
  background-color: rgba(25, 22, 19, 0.80);
  opacity: 0.8;
  color: rgba(255, 255, 255);
}

.transactions__transactions-table.table .blue-row td {
  background-color: #f0f0f0;
  color: rgba(25, 22, 19);
}

tr.tabRow.selected > *,
tr.transactionRow.selected > *,
.transactions__transactions-table.table tr.with-data:hover > td {
  cursor: pointer;
  background-color: rgb(185, 222, 241) !important;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* .transactions__table__font {} */

.report-column {
  padding-bottom: 30px;
}

/* @media only screen and (min-width : 425px) {
  .cafe-body .container__customize {

  }
} */

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .cafe-body .container__customize {
    width: 750px;
  }

  .footer__image {
    background-image: url('../images/logo.png');
    background-repeat: no-repeat;
    display: block;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    bottom: 50%;
    margin-bottom: -100px;
  }

  .container__customize {
    padding: 25px 10px 30px;
  }

  .receipt-and-products-container .floor-plan-mode__container {
    margin-right: -15px;
  }

  .cafe-body .floor-plan-mode__container {
    margin-right: 0;
  }

  .cafe-body .receipt-and-products-container {
    margin-top: 20px;
  }

  .status-text {
    padding: 15px 0;
  }

  .receipt {
    user-select: none;
    height: 460px;
  }

  .products__product__image-container {
    height: 60px;
  }

  .cafe-body .products__product__image-container {
    height: 56px;
  }

  .products__product {
    padding: 0 15px 30px;
  }

  .products__product__text {
    line-height: 39px;
  }

  .cafe-body .products__product__text {
    line-height: 32px;
    color: #f4f8ff;
  }

  .custom-products-row {
    padding-left: 15px;
    padding-right: 15px;
  }

  .cafe-body .custom-products-row {
    padding-left: 0;
    padding-right: 0;
  }

  .cafe-body .buttons {
    padding: 0 25px;
    margin-top: -10px;
  }

  .buttons__button {
    font-size: 16px;
    height: 60px;
    padding: 0 6px;
  }

  /* floor-plan */
  .floor-plan-circles-container {
    margin-top: 250px;
    padding-left: 50px;
  }

  .floor-plan-circles-container__circle-text__span {
    display: inline-block !important;
  }

  .floor-plan-circles-container__circle {
    float: left;
    height: 18px;
    width: 18px;
  }

  .floor-plan-circles-container__circle-text {
    float: left;
    margin: 9px 0 0 12px;
  }

  .custom-modal-large {
    width: 600px;
  }

  .floor-plan-image-container {
    width: 518px;
    height: 423px;
  }

  /* floor-plan-tables */
  .two-seat-table {
    width: 68px;
    height: 39px;
  }

  .eight-seat-table {
    width: 114px;
    height: 115px;
  }

  .six-seat-table {
    width: 70px;
    height: 115px;
  }

  .table-number {
    font-size: 16px;
    line-height: 36px;
  }

  .table-1 {
    margin: 24% 0 0 9%;
    transform: rotate(28deg);
  }

  .table-1 .table-number {
    transform: rotate(-28deg);
  }

  .table-2 {
    margin: 11% 0 0 20%;
    transform: rotate(61deg);
  }

  .table-2 .table-number {
    transform: rotate(-61deg);
  }

  .table-3 {
    margin: 8% 0 0 39%;
    transform: rotate(89deg);
  }

  .table-3 .table-number {
    transform: rotate(-90deg);
  }

  .table-4 {
    margin: 8% 0 0 59%;
    transform: rotate(89deg);
  }

  .table-4 .table-number {
    transform: rotate(-90deg);
  }

  .table-5 {
    margin: 26% 0 0 35%;
  }

  .table-6 {
    margin: 57% 0 0 20%;
  }

  .table-7 {
    margin: 57% 0 0 40%;
  }

  .table-8 {
    margin: 30% 0 0 72%;
  }

  .closed-tables-transactions-container {
    margin-left: 10px;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  .cafe-body .container__customize {
    width: 970px;
  }

  .container__customize {
    padding: 25px 10px 0;
  }

  .business-brand {
    padding: 5px 0;
    display: inline-block;
    width: 176px;
    padding: 7px 0;
    margin-bottom: 20px;
  }

  .pat-business-brand-desktop {
    border: 0px solid #000;
    padding: 46px 17px;
    margin: 0 0 -65px 10px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.13);
  }

  .business-brand__client-logo img {
    height: 98px;
    width: 147px;
    margin: auto;
  }

  .business-brand__client-name {
    line-height: 32px;
    display: block;
  }

  .receipt {
    height: 370px;
  }

  .cafe-body .receipt {
    height: 365px;
  }

  .products__product {
    padding: 0 5px 16px;
  }

  .buttons {
    margin-top: 5px;
    margin-bottom: 0;
  }

  .cafe-body .buttons {
    padding: 0 15px;
  }

  .buttons__button {
    font-size: 16px;
  }

  .custom-modal-large {
    width: 600px;
  }

  /* floor-plan */
  .floor-plan-image-container {
    float: right;
    margin-left: 150px;
  }

  .floor-plan-circles-container {
    margin: 0 0 0 35px;
  }

  .cafe-body .custom-products-row {
    padding-left: 25px;
  }

  .floor-plan-image-container {
    width: 454px;
    height: 371px;
  }

  /* floor-plan-tables */
  .two-seat-table {
    width: 68px;
    height: 39px;
  }

  .eight-seat-table {
    width: 114px;
    height: 115px;
  }

  .six-seat-table {
    width: 70px;
    height: 115px;
  }

  .table-number {
    font-size: 16px;
    line-height: 36px;
  }

  .table-1 {
    margin: 19% 0 0 6%;
    transform: rotate(28deg);
  }

  .table-1 .table-number {
    transform: rotate(-28deg);
  }

  .table-2 {
    margin: 8% 0 0 17%;
    transform: rotate(61deg);
  }

  .table-2 .table-number {
    transform: rotate(-61deg);
  }

  .table-3 {
    margin: 6% 0 0 32%;
    transform: rotate(89deg);
  }

  .table-3 .table-number {
    transform: rotate(-90deg);
  }

  .table-4 {
    margin: 6% 0 0 47%;
    transform: rotate(89deg);
  }

  .table-4 .table-number {
    transform: rotate(-90deg);
  }

  .table-5 {
    margin: 20% 0 0 28%;
  }

  .table-6 {
    margin: 43% 0 0 12%;
  }

  .table-7 {
    margin: 43% 0 0 29%;
  }

  .table-8 {
    margin: 22% 0 0 54%;
  }

  .closed-tables-transactions-container {
    margin-left: 0;
  }

  
}

/* Large devices (large desktops) */
@media (min-width: 1200px) {
  .retail-body .receipt {
    height: 460px;
  }

  .cafe-body .receipt {
    height: 420px;
  }

  .cafe-body .container__customize {
    width: 1170px;
  }

  .products__product {
    padding: 0 15px 30px;
  }

  .custom-modal-large {
    width: 600px;
  }

  .cafe-body .buttons {
    padding: 0 25px;
  }

  .floor-plan-image-container {
    width: 587px;
    height: 479px;
  }

  /* floor-plan-tables */
  .two-seat-table {
    width: 92px;
    height: 53px;
  }

  .eight-seat-table {
    width: 156px;
    height: 156px;
  }

  .six-seat-table {
    width: 93px;
    height: 155px;
  }

  .table-number {
    font-size: 16px;
    line-height: 47px;
  }

  .table-1 {
    margin: 19% 0 0 6%;
    transform: rotate(28deg);
  }

  .table-1 .table-number {
    transform: rotate(-28deg);
  }

  .table-2 {
    margin: 8% 0 0 17%;
    transform: rotate(61deg);
  }

  .table-2 .table-number {
    transform: rotate(-61deg);
  }

  .table-3 {
    margin: 6% 0 0 32%;
    transform: rotate(89deg);
  }

  .table-3 .table-number {
    transform: rotate(-90deg);
  }

  .table-4 {
    margin: 6% 0 0 47%;
    transform: rotate(89deg);
  }

  .table-4 .table-number {
    transform: rotate(-90deg);
  }

  .table-5 {
    margin: 21% 0 0 27%;
  }

  .table-6 {
    margin: 46% 0 0 12%;
  }

  .table-7 {
    margin: 46% 0 0 31%;
  }

  .table-8 {
    margin: 24% 0 0 57%;
  }

  .buttons {
    margin-top: 15px;
  }
}